import { useMutation } from 'react-query';
import { ServerResponse } from '../../hooks/useAxiosConfig';
import { apiHttp, queryClient } from '../../lib/appConfig';
import { useLocalStorage } from './../../hooks/useLocalStorage';
import { ICredentials } from './auth.interface';
import { IAuth, IUser } from '@primaljournal/commons';
import { authQueryKeys } from './auth.queries';

async function verifyEmail(token: string) {
    const res = await apiHttp.get<ServerResponse<boolean>>(
        '/auth/email-verification/' + token
    );
    return res.data.data;
}

async function sendEmailVerification() {
    const res = await apiHttp.get<ServerResponse<boolean>>(
        '/auth/email-verification'
    );
    return res.data.data;
}

async function passwordRequest(data: { password: string; token: string }) {
    const res = await apiHttp.post<
        ServerResponse<{
            message: string;
            status: boolean;
        }>
    >('/auth/reset-password', data);
    return res.data.data;
}

async function sendForgotPasswordRequest(data: { email: string }) {
    const res = await apiHttp.post<
        ServerResponse<{
            message: string;
            status: boolean;
        }>
    >('/auth/recover', data);
    return res.data.data;
}

async function contactUs(data: {
    email: string;
    name: string;
    message: string;
    phone: string;
    subject: string;
}) {
    const res = await apiHttp.post<
        ServerResponse<{
            message: string;
            status: boolean;
        }>
    >('/email/contact-us', data);
    return res.data.data;
}

export const logoutUser = async () => {
    const res = await apiHttp.get<ServerResponse<unknown>>('/auth/logout');
    return res.data.data;
};

const loginWithEmailAndPassword = async ({ email, password }: ICredentials) => {
    const login = await apiHttp.post<ServerResponse<IAuth>>('/auth/login', {
        email,
        password,
    });

    return login.data.data;
};

const updateUser = async ({
    updates,
    userId,
}: {
    updates: Partial<IUser>;
    userId: string;
}) => {
    if (!userId) return null;
    const update = await apiHttp.patch<ServerResponse<IAuth>>(
        '/users/' + userId,
        updates
    );

    return update.data.data;
};

const registerWithEmailAndPassword = async ({
    email,
    password,
    firstName,
    lastName,
    middleName,
}: ICredentials) => {
    const register = await apiHttp.post<ServerResponse<IAuth>>(
        '/auth/register',
        {
            email,
            password,
            firstName,
            lastName,
            middleName,
        }
    );

    return register.data.data;
};

export const useResetPassword = () => {
    return useMutation(passwordRequest);
};

export const useForgotPassword = () => {
    return useMutation(sendForgotPasswordRequest);
};

export const useContactUs = () => {
    return useMutation(contactUs);
};

export const useLogout = () => {
    const { resetLocalStorage } = useLocalStorage();
    return useMutation(logoutUser, {
        onSuccess: async () => {
            resetLocalStorage();
            await queryClient.cancelQueries(authQueryKeys.all);
            window.location.href = '/';
            window.location.reload();
        },
    });
};

export const useLoginWithEmailAndPassword = () => {
    return useMutation(loginWithEmailAndPassword);
};

export const useRegisterWithEmailAndPassword = () => {
    return useMutation(registerWithEmailAndPassword);
};

export const useUpdateUser = () => {
    return useMutation(updateUser);
};

export const useSendEmailVerification = () => {
    return useMutation(sendEmailVerification);
};

export const useVerifyEmail = () => {
    return useMutation(verifyEmail);
};
