import {
    Avatar,
    Box,
    Button,
    Flex,
    SimpleGrid,
    Text,
    useColorMode,
} from '@chakra-ui/react';
import { MdOutlineLogout } from 'react-icons/md';
import {
    useAuth,
    useUserRoles,
} from '../../services/authentication/auth.queries';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import { normalize_property_name, ROUTES } from '../../App.constant';
import useModeColor from '../../hooks/useDynamicModeColor';

import { Link } from 'react-router-dom';
import { RiDashboardLine, RiPagesLine } from 'react-icons/ri';
import { ROLES_TYPE } from '@primaljournal/commons';
import { useMemo } from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useLogout } from '../../services/authentication/auth.mutation';

interface IProps {
    close?: () => void;
}

export const SideNavProfilePopup = (props: IProps) => {
    const { setCurrentRole, roleState } = useUserRoles();
    const { borderColor } = useModeColor();
    const { data } = useAuth();
    const { mutate: logout } = useLogout();
    const navigate = useNavigate();
    const { colorMode } = useColorMode();

    const dashboardLandingPage: {
        [key in ROLES_TYPE]: {
            to: string;
            label: string;
            icon: ReactJSXElement;
        };
    } = {
        Admin: {
            to: ROUTES.DASHBOARD,
            label: 'Dashboard',
            icon: <RiDashboardLine size={24} />,
        },
        Author: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiPagesLine size={24} />,
        },
        Reviewer: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Review Requests',
            icon: <RiDashboardLine size={24} />,
        },
        Editor: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Review Requests',
            icon: <RiDashboardLine size={24} />,
        },
        ManagerialEditor: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiDashboardLine size={24} />,
        },
        EditorialSecretary: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiDashboardLine size={24} />,
        },
        ChiefEditor: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiDashboardLine size={24} />,
        },
        Blogger: {
            to: ROUTES.BLOGS,
            label: 'Dashboard',
            icon: <RiDashboardLine size={24} />,
        },
        Support: {
            to: ROUTES.DASHBOARD,
            label: 'Dashboard',
            icon: <RiDashboardLine size={24} />,
        },
    };

    const selectedLanding = useMemo(() => {
        if (
            roleState?.activeRole &&
            dashboardLandingPage[roleState?.activeRole]
        ) {
            return dashboardLandingPage[roleState?.activeRole];
        }

        return null;
    }, [roleState?.activeRole]);

    return (
        <Box
            transition=".3s ease"
            bg={colorMode === 'dark' ? 'black' : 'white'}
        >
            <Flex alignItems="center" gap="4" p={4}>
                <Avatar
                    size="sm"
                    src={data?.user?.profilePhoto}
                    name={data?.user?.firstName}
                    borderRadius="lg"
                />
                <SimpleGrid>
                    <Text fontSize="sm" fontWeight="bold">
                        {data?.user?.firstName} {data?.user?.lastName}
                    </Text>
                    <Text fontSize="xs" wordBreak="break-all">
                        {data?.email}
                    </Text>
                </SimpleGrid>
            </Flex>

            {data && data?.roles.length > 1 && (
                <SimpleGrid borderY="1px" borderColor={borderColor}>
                    {data?.roles.map((r, i) => (
                        <Button
                            borderRight={
                                roleState?.activeRole === r ? '4px' : 0
                            }
                            borderColor="primary"
                            key={i}
                            px={4}
                            py={6}
                            display="flex"
                            justifyContent="left"
                            rounded="none"
                            variant="ghost"
                            fontSize="small"
                            leftIcon={
                                <Avatar
                                    size="sm"
                                    borderRadius="lg"
                                    src={r}
                                    name={r}
                                />
                            }
                            onClick={() => {
                                setCurrentRole(r);
                                navigate(ROUTES.DASHBOARD);
                                if (props?.close) props?.close();
                                setTimeout(() => window.location.reload(), 100);
                            }}
                        >
                            <Flex alignItems="center" gap="2">
                                <SimpleGrid>
                                    <Text>{normalize_property_name(r)}</Text>
                                </SimpleGrid>
                            </Flex>
                        </Button>
                    ))}
                </SimpleGrid>
            )}

            <SimpleGrid>
                {selectedLanding && (
                    <Link to={selectedLanding.to}>
                        <Button
                            px={4}
                            display="flex"
                            justifyContent="left"
                            rounded="none"
                            variant="ghost"
                            fontSize="small"
                            leftIcon={selectedLanding.icon}
                            w="100%"
                        >
                            {selectedLanding.label}
                        </Button>
                    </Link>
                )}
                <Link to={ROUTES.PERSONAL_INFORMATION_SETTINGS}>
                    <Button
                        w="100%"
                        px={4}
                        display="flex"
                        justifyContent="left"
                        rounded="none"
                        variant="ghost"
                        fontSize="small"
                        leftIcon={<FiSettings size={24} />}
                    >
                        Settings
                    </Button>
                </Link>
            </SimpleGrid>
            <SimpleGrid>
                <Button
                    px={4}
                    display="flex"
                    justifyContent="left"
                    rounded="none"
                    variant="ghost"
                    fontSize="small"
                    leftIcon={<MdOutlineLogout size={24} />}
                    onClick={() => {
                        logout();
                    }}
                >
                    Logout
                </Button>
            </SimpleGrid>
        </Box>
    );
};
